import { render, staticRenderFns } from "./TableColumnPopup.vue?vue&type=template&id=7a87c57c&scoped=true&"
import script from "./TableColumnPopup.vue?vue&type=script&lang=ts&"
export * from "./TableColumnPopup.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a87c57c",
  null
  
)

export default component.exports