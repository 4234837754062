
/* Ordena el header secundario con respecto al header primario */
export function orderHeaderSecondary(headerPrimary: Array<any>, headerSecondary: any){ 
        let orderedHeaderSecondary: Array<any> = [];
        if (headerPrimary.length > 0){  // Si existe un header primario
            let pinedColumn : any = headerSecondary.find((el: { pined: any; }) => el.pined === true)
            if (pinedColumn) orderedHeaderSecondary.push(pinedColumn);
            headerPrimary.forEach((el: { children: any[]; }) =>{
                    if (el.children){
                            el.children.forEach((children: any)  =>{
                                    let element = headerSecondary.find((header: { key: any; pined: boolean}) => header.key === children.key && !header.pined)
                                    if (element) orderedHeaderSecondary.push(element)
                            })  
                    }
            })
         }
         else orderedHeaderSecondary = headerSecondary; 
        return orderedHeaderSecondary;
    }
    
    
    export function existingPinedColumn(header: any): boolean{
            let found = header.find((el: { pined: boolean;}) => el.pined === true)
            if (found) return true
            else return false
    }
    
    export function getPinedColumn(header: any): any {
            return header.find((el: { pined: boolean;}) => el.pined === true)       
    }
    
    export function addPinedColumnInPrimaryHeader(child: any, headerPrimary: any){
            child.pined = true;
            let object ={
                    "name": 'Pined',
                    "key": 'pin',
                    "shown": true,
                    "children": [
                        child
                    ]
            }
            headerPrimary.unshift(object);
            return headerPrimary
    }
    
    export function removeColumn(column: any, col: any){
            let newHeader : any = []
                column.forEach((el: { key: any; }) =>{
                    if (el.key !== col.key){
                        newHeader.push(el)
                    }
                })
            return newHeader
    }
    
export function removeColumnChild(headerPrimary: any, parent: any, child: any){ // Retorna el header sin el hijo pasado por parámetro
            let newParentChilds = removeColumn(parent.children, child); // Retorna los nuevos hijos de la columna padre
            let newHeaderPrimary = headerPrimary;
            newHeaderPrimary.forEach( (el: { key: any; children: any; }) =>{ 
                if (el.key === parent.key) el.children = newParentChilds;
            })
            return newHeaderPrimary;
}